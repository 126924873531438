<template>
  <div>
    <!-- <div class="title">
      <i class="el-icon-arrow-left icon" @click="$router.go(-1)"></i>任务结算
    </div> -->
    <el-card class="main">
      <div class="nav">
        <div class="jindu jindu1">上传结算列表</div>
        <div class=" jindu jindu2">上传结算单</div>
        <div class="jindu jindu3">提交结算申请</div> -->
      </div>
      <div style="margin-top:10px">
        <span style="font-size: 16px">{{ Detailstr.taskName }}</span>
        <span class="mini">{{Detailstr.taskStateName}}</span>
      </div>
      <div class="two">
        起始日期 {{ Detailstr.taskStartTime }} ~ {{ Detailstr.taskEndTime }}
        <span class="listspan"
          >结算周期 {{ Detailstr.settlementCycleName }}</span
        >
        <span class="listspan"
          >结算标准 {{ Detailstr.price }}
          {{ Detailstr.settlementStandardName }}</span
        >
        <!-- <span class="listspan">所属行业 {{ Detailstr.industryName }}</span> -->
      </div>
      <div>
        <p>申请结算</p>
        <p>人数：{{SettInfo.personNums}}</p>
        <p>金额：{{SettInfo.amount}} 元</p>
      </div>
       <div style="margin-top:30px">
         <el-button style="margin-left:20px" type="primary" size="mini" @click="exportlist()">导出</el-button> 
      
      <el-table
      v-loading="load"
      :header-cell-style="{background:'#9e99b9',color:'#ffff'}"
      :data="tableData"
      style="width: 100%;margin-top: 20px">
      <el-table-column
       header-align="center"
        align="center"
        prop="name"
        label="姓名">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="idCard"
        label="身份证号">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="creditCode"
        label="统一社会代码">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="amount"
        label="金额">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="price"
        label="结算单价(元/天)">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="workload"
       
        label="数量">
      </el-table-column>
       <el-table-column
       header-align="center"
        align="center"
        prop="remarks"
       
        label="备注">
      </el-table-column>
    </el-table>
     <el-pagination
            background
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="currPage"
            :page-sizes="[10, 20,30, 50, 100]"
            :page-size="pageSize"
            :total="totalCount"
            class="text_center"
            layout="total,  prev, pager, next, sizes,jumper"
             style="text-align:right;margin-top:30px"
     ></el-pagination> 
       </div>
       <!-- 结算单 -->
       <div>
         <p style="font-size:16px;margin-top:50px">结算单</p>
         <div class="dia">
           <img :src="SettInfo.url" alt="" class="img">
         </div>
         <div class="dia" style="height:100px">
            <el-button style="margin-left:20px" type="primary"  @click="SubmitApply()">提交申请</el-button> 
        </div>
         
       </div>
    
    </el-card>
    
<el-dialog
  title="提交任务结算申请成功"
  :visible.sync="dialogVisible"
  width="30%"
  
  >
  <div  id="dia">
   <el-form  :model="submitData" label-width="140px" class="form">
             <el-form-item label="结算人数">
                <el-input v-model="submitData.applyPersonNum" ></el-input>
            </el-form-item>
            <el-form-item label="结算金额">
                <el-input v-model="submitData.applyAmount" ></el-input>
            </el-form-item>
        </el-form>
  </div>
  <p style="margin-top:20px">本次结算已经提交，结算结果会在1个小时内完成并展示</p>
</el-dialog>
<el-dialog
  title="提交任务结算申请失败"
  :visible.sync="dialogVisiblefail"
  width="30%">
  <div  id="dia">
   <el-form  :model="submitData" label-width="160px" class="form">
            <el-form-item label="结算人数">
                <el-input v-model="submitData.applyPersonNum" ></el-input>
            </el-form-item>
            <el-form-item label="结算金额">
                <el-input v-model="submitData.applyAmount" ></el-input>
            </el-form-item>
            <el-form-item label="账户可结算贷款余额">
                <el-input v-model="submitData.balance" disabled></el-input>
            </el-form-item>
        </el-form>
    </div>
    <p style="margin-top:20px">请先充值后再进行结算</p>
</el-dialog>
  </div>
</template>

<script>
import { export2Excel } from '@/utils/util'
import { getDetail,orderSubmitApply,taskOrderItemPageList,SettInfoByOrderId} from "@/api/task";
export default {
  props:['orderId'],
  data() {
    return {
    load:false,
    Detailstr: {},
    SettInfo:{},
    tableData: [],
    pageSize: 10,
    totalCount: 0,//数据长度
    currPage: 1,
    exportList:[],//导出数组
    columns:[
        {
            title:"姓名",
            key:"name"
          },
           {
            title:"身份证号",
            key:"idCard"
          },
           {
            title:"统一社会代码",
            key:"creditCode"
          },
           {
            title:"金额",
            key:"amount"
          },
           {
            title:"结算单价(元/天)",
            key:"price"
          },
          {
            title:"数量",
            key:"workload"
          },
          {
            title:"备注",
            key:"remarks"
          }],//导出表格
    dialogVisible:false,
    dialogVisiblefail:false,
    submitData:{},
    };
  },
  created() {},
  mounted() {
		this.Detail()
   
	},
  activated() {},
  methods: {
    // 上部详情
    Detail() {
     getDetail({
        taskId: this.$route.query.taskId,
      }).then((data) => {
        if (data && data.code == 200) {
          this.Detailstr = data.data;
          this.list()
        }
      });
        SettInfoByOrderId(this.orderId).then(data=>{
         if (data && data.code==200) {
            this.SettInfo= data.data
          }
      })
    },
     list(){
       taskOrderItemPageList({
          "validationResults":1,
          "orderBatchId":this.orderId,
          "industryCode":this.Detailstr.industryCode,
          "current": this.currPage,
          "size": this.pageSize,
        }).then(data=>{
          if (data && data.code==200) {
            this.totalCount=data.data.total
             this.tableData=data.data.records
          }
        })
      },
        sizeChangeHandle(val) {
        this.pageSize = val;
        this.currPage = 1;
        this.list();
      },
      // 当前页
      currentChangeHandle(val) {
        this.currPage = val;
        this.list();
      },
      // 导出list
    exportlist(){
      let info = {
          "validationResults":1,
          "orderBatchId":this.orderId,
          "industryCode":this.Detailstr.industryCode,
          "current": 1,
          "size": 9999,
        }
      taskOrderItemPageList(info).then(data =>{
        if (data.data.records!="" && data.code==200) {
           let orderNumber= data.data.records[0].batchNo
              this.exportList = data.data.records
               this.exportList.map(item=>{
                if (item.checkState==1) {
                   item.checkState="通过"
                   return
                }
              })
               export2Excel(this.columns,this.exportList,this.Detailstr.taskName + orderNumber + "通过")
           }
       })
    },
    // 提交任务
    SubmitApply(){
       orderSubmitApply(this.orderId).then((data) => {
        if (data && data.code == 200) {
          this.$forceUpdate()
          if (data.data.isTrue) {
            this.dialogVisible=true
            this.submitData = data.data;
            setTimeout(()=>{ this.$router.push("/task-settlement");},3000)
            return
          }
          this.dialogVisiblefail=true
          this.submitData = data.data;
          setTimeout(()=>{ this.$router.push("/task-settlement");},3000)
        }
      });
    },
    edit(){
      this.$emit("Flagclick3", false);
    }
      
   },
};
</script>

<style  scoped>
.main {
  margin-top: 10px;
  /* height: 900px; */
}
.mini {
  display: inline-block;
  height: 20px;
  line-height: 20px;
  text-align: center;
  width: 60px;
  border: 1px solid #9e99b9;
  border-radius: 25px;
  margin-left: 14px;
  transform: translateY(-2px);
  font-size: 10px;
}
.two {
  font-size: 13px;
  margin-top: 10px;
}
.listspan {
  margin-left: 30px;
}
.nav {
  width: 110%;
  height: 35px;
  line-height: 32px;
  text-align: center;
  margin-top: -20px;
  margin-left: -20px;
  color: #ffff;
  font-size: 13px;
  
}
.nav div{
 display: inline-block;
 height: 32px;
  width: 530px;
  background-size: cover;
}
.jindu1 {
  background-image: url("~pub/img/a1-1.png");
  margin-left: -106px; 
}
.jindu2 {
  background-image: url("~pub/img/a2.png");
  margin-left: 84px;
}
.jindu3 {
  margin-left: 84px;
  background-image: url("~pub/img/a3.png");
}
.dia{
 margin: auto !important;
  text-align: center;
}
.font{
  text-align: center;
  font-size: 14px;
}
/deep/.el-dialog__header{
  background-color: #9e99b9;
 
  }
/deep/  .el-dialog__title{
 color:#ffff;
}
/*  */
/* 表格------------- */
  /deep/ #dia .form {
    margin-top:10px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-top: 1px solid #ccc;
}
 #dia  .el-form-item {
  margin-bottom: 0px;
  border-bottom: 1px solid #ccc;
}
div /deep/ #dia  .el-input__inner {
  border: #fff;
}
div /deep/ #dia .el-form-item__label {
    text-align: center;
    background: #f0f0f0;
    /* // color: black; */
    border-right: 1px solid #ccc;
}
div /deep/ #dia .el-input.is-disabled .el-input__inner {
    text-align: center;
    background: #FFF;
    color: black;
}
div /deep/ #dia .el-input .el-input__inner {
    text-align: center;
    background: #FFF;
    color: black;
}
/deep/.el-dialog__headerbtn .el-dialog__close {
  color: #fff;
}
/deep/.el-dialog__headerbtn .el-dialog__close:hover {
  color: #fff;
}
</style>


